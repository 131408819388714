import { ProductClass } from '../../graphQL/graphQLTypes';
import {
  formatECI,
} from '../../numberFormatters';

export enum Axis {
  X,
  Y,
}

export const getAverageLineLabel = (averageValue: number, year: number, productClass: ProductClass) => {
  let explanationText: string, formattedNumber: string;
  explanationText = `${__lexiconText('averageLineLabel.complexity')} (${productClass} ${year})`;
  formattedNumber = formatECI(averageValue);
  return `${explanationText}: ${formattedNumber}`;
};
