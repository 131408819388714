import React from 'react';
import styled from 'styled-components';
import {
  demiFontWeight,
  localTooltipZIndex,
} from '../cssVariables';
import {
  ProductClass,
  ProductLevel,
} from '../graphQL/graphQLTypes';
import {
  applyMatrixToPoint,
  ITransformationMatrix,
} from '../network/panZoom';
import {
  H7,
} from '../sharedComponents/TextStyling';
import {
  isInRange, newGetDisplayedProductCode,
} from '../Utils';

const tooltipFillColor = 'rgb(25, 25, 70)';
const tooltipBorderColor = 'rgb(180, 180, 180)';

// These are in `vw` units:
const tooltipWidth = 12;
const arrowHeight = tooltipWidth / 15;
const arrowWidth = arrowHeight;

// This is the root element for the highlight tooltip.
// Use a different `z-index` for hover tooltip:
export const Root = styled.div`
  --translation-x: 0;
  --translation-y: 0;

  top: -${arrowHeight}vw;
  left: 1px;
  z-index: ${localTooltipZIndex};
  position: absolute;
  max-width: ${tooltipWidth}vw;
  transform: translateX(calc(-50% + var(--translation-x))) translateY(calc(-100% + var(--translation-y)));
  opacity: 0.75;
  display: flex;
  color: rgba(255, 255, 255, 1);
  background-color: ${tooltipFillColor};
  padding: 8px;
  border-style: dotted;
  border-radius: 2px;
  border-color: ${tooltipBorderColor};
  border-width: 1px;
  will-change: transform;
  pointer-events: none;
  font-size: 0.9rem;
  font-weight: ${demiFontWeight};
  line-height: 1.5;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-bottom: 0;
  }

  &::before {
    bottom: -${arrowHeight}vw;
    left: 50%;
    transform: translateX(-50%);
    border-top-color: ${tooltipBorderColor};
    border-width: ${arrowWidth}vw;
  }

  &::after {
    bottom: calc(-${arrowHeight}vw + 1px);
    left: 50%;
    transform: translateX(-50%);
    border-top-color: ${tooltipFillColor};
    border-width: calc(${arrowWidth}vw - 1px);
  }
`;

const Content = styled.div`
  opacity: 1;
`;

const Name = styled.span`
  &::after {
    content: '|';
    margin-right: 0.2rem;
    margin-left: 0.2rem;
  }
`;
const Code = styled(H7)`
  opacity: 0.5;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;
  padding: 0 0.2rem 0.3rem 0.5rem;
  color: #fff;
  cursor: pointer;
  pointer-events: auto;
`;

interface Props {
  svgWidth: number | undefined;
  svgHeight: number | undefined;
  productName: string;
  productCode: string;
  productClass: ProductClass;
  productLevel: ProductLevel;
  // vert and horz translations, in pixels:
  xOffset: number;
  yOffset: number;
  transformationMatrix: ITransformationMatrix;
  closeTooltip: () => void;
}

const NetworkTooltip = (props: Props) => {
  const {
    productCode, productName, xOffset, yOffset,
    transformationMatrix, productClass,
    svgWidth, svgHeight, productLevel, closeTooltip,
  } = props;
  const {x, y} = applyMatrixToPoint(transformationMatrix, xOffset, yOffset);

  if (svgWidth !== undefined && svgHeight !== undefined &&
      isInRange(x, 0, svgWidth) && isInRange(y, 0, svgHeight)) {

    const style = {
      '--translation-x': `${Math.round(x)}px`,
      '--translation-y': `${Math.round(y)}px`,
      'padding': closeTooltip === undefined ? '8px' : '16px 8px 8px',
    };
    const text = `${productName}`;

    const closeButton = closeTooltip === undefined ? null : (
      <CloseButton onClick={closeTooltip}>×</CloseButton>
    );

    return (
      <Root style={style}>
        {closeButton}
        <Content>
          <Name>{text}</Name><Code>({newGetDisplayedProductCode(productCode, productClass, productLevel)})</Code>
        </Content>
      </Root>
    );
  } else {
    return null;
  }
};

export default NetworkTooltip;
