import reverse from 'lodash-es/reverse';
import uniq from 'lodash-es/uniq';
import React from 'react';
import styled from 'styled-components';
import {
  ProductClass,
} from '../../graphQL/graphQLTypes';
import {
  ITransformationMatrix,
} from '../../network/panZoom';
import Tooltip from '../../sharedComponents/NewNetworkTooltip';
import {
  IProcessedNode,
} from '../../workerStore/feasibility/Utils';
import {
  ChartRoot,
} from '../Grid';

const TooltipsInnerContainer = styled(ChartRoot)`
  position: relative;
`;
interface Props {
  tooltipMap: Record<string, IProcessedNode>;
  hoveredProduct: string | undefined;
  highlightedProduct: string | undefined;
  selectedProducts: string[];
  svgWidth: number | undefined;
  svgHeight: number | undefined;
  transformationMatrix: ITransformationMatrix;
  productClass: ProductClass;
  closeTooltip: (id: string) => void;
}

const TooltipsContainer = (props: Props) => {
  const {
    tooltipMap, hoveredProduct, highlightedProduct, selectedProducts,
    svgHeight, svgWidth, transformationMatrix, productClass, closeTooltip,
  } = props;

  if (svgHeight === undefined || svgWidth === undefined) {
    return null;
  } else {
    // Note: the tooltips have the following "z-index" order: hover tooltips
    // on top, followed by selection tooltips followed by highlight tooltip on
    // the bottom. We add them to the `tooltipIDs` array in the opposite order
    // (hover first, highlighted last) to accommodate the `uniq` function,
    // which retains the first occurrence:
    let tooltipIDs: string[] = [];
    if (hoveredProduct !== undefined) {
      tooltipIDs = [hoveredProduct];
    }
    tooltipIDs = [...tooltipIDs, ...selectedProducts];
    if (highlightedProduct !== undefined) {
      tooltipIDs = [...tooltipIDs, highlightedProduct];
    }
    const uniqueIDs = reverse(uniq(tooltipIDs));
    const tooltipElems = uniqueIDs.map(id => {
      const retrievedTooltipDatum = tooltipMap[id];
      if (retrievedTooltipDatum === undefined) {
        throw new Error('Cannot find tooltip datum for product ' + id);
      }
      const {x, y, shortLabel, code, level} = retrievedTooltipDatum;
      return (
        <Tooltip key={id}
          svgHeight={svgHeight}
          svgWidth={svgWidth}
          productClass={productClass}
          productLevel={level}
          transformationMatrix={transformationMatrix}
          productName={shortLabel}
          productCode={code}
          xOffset={x}
          yOffset={y}
          closeTooltip={() => closeTooltip(id)}
        />
      );
    });

    return (
      <>
        <TooltipsInnerContainer>
          {tooltipElems}
        </TooltipsInnerContainer>
      </>
    );

  }

};

export default TooltipsContainer;
